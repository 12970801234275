<!-- 联系我们 -->
<template>
  <section class="contact" id="contact-anchor-point">
    <h2 class="title">联系我们</h2>
    <p>
      地址：陕西省西安市雁塔区太白南路220号<br />
      联系电话：15529549717<br />
      联系邮箱：qihepai@163.com<br />
      更多有趣内容，请搜索小程序：契合派
    </p>
  </section>
</template>

<script>
export default {
  name: 'Contact',
}
</script>

<style lang="less" scoped>
.contact {
  padding-top: 100px;
  .title {
    position: relative;
    margin: 0;
    color: #3f4658;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 1px;
  }
  p {
    margin: 60px 0;
    color: #747e8b;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
  }
  img {
    width: 100%;
    background: #fff;
    display: block;
  }
}
</style>
