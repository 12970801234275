<!-- 关于我们 -->
<template>
  <section class="about" id="about-anchor-point">
    <h2 class="title">爱上"契合派"</h2>
    <p class="content"> "契合派"于2023年6月成立团队，其初衷是“让你遇见契合的人” </p> 
    <p class="content"> 由西安契合派科技有限公司运营，2024年10月正式推出"契合派"小程序</p> 
    <p class="content-big"> 当月红娘突破30位，会员资料突破 16000 +... </p>
    <ul class="points">
      <li v-for="(item, index) in aboutUsItems" :key="index">
        <div class="bg" :style="{ 'background-image': 'url(' + item.imageUrl + ')' }"></div>
        <span>{{ index + 1 < 10 ? '0' + (index + 1) : index + 1 }}</span>
        <h3>{{ item.title }}</h3>
        <p>{{ item.content }}</p>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      // 关于我们
      aboutUsItems: [
      
        {
          imageUrl: '//imguki.neoclub.cn/miaohong/outer-h5/ukiTeam.jpg',
          title: '平台功能',
          content: '你可以申请成为红娘，与此同时发现契合的灵魂，利用搭子功能，快速速找到到搭子圈',
        },
        {
          imageUrl: '//imguki.neoclub.cn/miaohong/outer-h5/joinUs.jpg',
          title: '加入我们',
          content: '任何有理想的人都可以与我们联系，我们可以共同构建一个有价值的社群',
        },
        {
          imageUrl: '//imguki.neoclub.cn/miaohong/outer-h5/ukiSpec.jpg',
          title: '宝贵意见',
          content: '“契合派”的成长离不开你们的提醒和反馈、愿我们共同成长',
        },
        
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.about {
  padding-top: 100px;
  background: #f8f6fa;
  .title {
    position: relative;
    margin: 0;
    color: #3f4658;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 1px;
  }
  .content {
    width: 690px;
    margin: 20px auto;
    color: #747e8b;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
  }
  .content-big{
    width: 690px;
    margin: 20px auto;
    color: #eb3604;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
  }
  .points {
    width: 930px;
    height: 400px;
    margin: 0 auto;
    padding: 0;
    border-radius: 12px;
    list-style: none;
    overflow: hidden;
    display: flex;
    li {
      position: relative;
      width: 310px;
      height: 100%;
      color: #fff;
      text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .bg {
        box-sizing: border-box;
        background-size: cover;
        background-position-x: -85px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .bg::before {
        content: '';
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: filter 0.3s, box-shadow 0.3s; // 添加过度效果
      }
      span {
        position: relative;
        margin-bottom: 20px;
        font-size: 24px;
        z-index: 1;
      }
      h3 {
        position: relative;
        margin: 0;
        font-size: 36px;
        font-weight: 500;
        z-index: 1;
      }
      p {
        white-space: pre-line;
        position: relative;
        width: 260px;
        height: 0;
        margin-top: 20px;
        padding-top: 38px;
        font-size: 18px;
        line-height: 30px;
        z-index: 1;
        opacity: 0;
        overflow: hidden;
        transition: all 0.2s;
      }
      p::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 80px;
        height: 1px;
        background: #fff;
        border-radius: 1px;
        display: block;
        transform: translateX(-50%);
      }
    }
    li:hover .bg {
      filter: blur(5px); // 背景模糊
      box-shadow: inset 0 0 0 5px #fff; // 背景内阴影
    }
    li:hover p {
      opacity: 1;
      height: 160px;
      overflow: auto;
    }
  }
}
</style>
