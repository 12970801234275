<!-- 立即下载 -->
<template>
  <section class="download-now">
    <h2>16000+注册用户</h2>
    <h2>都在契合派朋友圈</h2>
    <a>立即打开契合派小程序</a>
  </section>
</template>

<script>
export default {
  name: 'DownloadNow',
  props: {},
}
</script>

<style lang="less" scoped>
.download-now {
  height: 500px;
  color: #fff;
  background: #f8f6fa url(//imguki.neoclub.cn/miaohong/offical/new-uki/bg-4.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    width: 590px;
    margin: 0 0 60px;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 1px;
  }

  a {
    width: 200px;
    height: 54px;
    font-size: 18px;
    text-align: center;
    line-height: 50px;
    border: 2px solid hsla(0, 0%, 100%, 0.2);
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s;
  }
}
</style>
