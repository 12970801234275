<!-- 玩法介绍 -->
<template>
  <section class="feature" id="feature-anchor-point">
    <h2 class="title">契合派 玩法介绍</h2>
    <section class="wrapper">
      <ul class="features">
        <li>
          <img src="https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/top-01.jpeg" alt="feature" draggable="false" />
          <section>
            <h3>
              最优推荐/
              <span>01</span>
            </h3>
            <p>大屏预览，关注即可畅聊，用你的有趣和可爱吸引Ta，让交友变得如此轻松自在。</p>
          </section>
        </li>
        <li>
          <section>
            <h3>
              心动关注/
              <span>02</span>
            </h3>
            <p>标记心动用户，动态实时获取，从细节出发，爱就要马上行动。</p>
          </section>
          <img src="https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/top-02.jpeg" alt="feature" draggable="false" />
        </li>
        <li>
          <img src="https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/top-03.jpeg" alt="feature" draggable="false" />
          <section>
            <h3>
              动态广场/
              <span>03</span>
            </h3>
            <p>匿名广场，告别熟人，在这里你可以真实地展示&amp;释放自我，每一次分享都有人回应，每一次自我表达都有人懂。</p>
          </section>
        </li>
        <li>
          <section>
            <h3>
              搭子派对/
              <span>04</span>
            </h3>
            <p>有趣的人在这里，搭子派对在线上到线下，帮助你快速结交新朋友。</p>
          </section>
          <img src="https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/top-04.jpeg" alt="feature" draggable="false" />
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
export default {
  name: 'Feature',
  props: {},
}
</script>

<style lang="less" scoped>
// 玩法介绍
.feature {
  width: 100%;
  padding: 70px 0 96px;
  background: url(//imguki.neoclub.cn/miaohong/offical/new-uki/bg-1.png), url(//imguki.neoclub.cn/miaohong/offical/new-uki/bg-2.png), url(//imguki.neoclub.cn/miaohong/offical/new-uki/bg-3.png);
  background-size: 546px 552px, 720px 852px, 100% 1028px;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: 0 404px, right 455px, left 1304px;

  .title {
    position: relative;
    margin: 0;
    color: #3f4658;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 1px;
  }

  .wrapper {
    max-width: 930px;
    margin: auto;

    .features {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-bottom: 75px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 400px;
          display: block;
        }

        h3 {
          position: relative;
          margin: 0 0 18px;
          color: #8c75ea;
          font-size: 36px;
          font-weight: 500;
          line-height: 50px;

          span {
            position: relative;
            left: -78px;
            color: rgba(140, 117, 234, 0.1);
            font-size: 100px;
            font-weight: 600;
            line-height: 122px;
          }

          p {
            width: 400px;
            color: rgba(116, 126, 139, 0.7);
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>
