<template>
  <div>
    <!-- 导航栏 -->
    <nav class="nav" :class="{ scrolled: isScrolled }">
      <section class="wrapper">
        <div class="logo-container">
          <img src="https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/logo.png" alt="logo" class="logo" />
          <div class="qh-container">
            <img src="https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/%E5%A5%91%E5%90%88%E6%8B%8D%E6%96%87%E5%AD%97.png" alt="logo" class="qh" />
            <span class="small-text">发现同频的人</span>
          </div>
        </div>
        <ul>
          <li v-for="(item, index) in navItems" :key="index" @click="handleItemClick(item)" :class="{ active: item.id === selectedNavItem?.id }">{{ item.name }}</li>
        </ul>
      </section>
    </nav>
    <!-- 封面 -->
    <Cover />
    <!-- 玩法介绍 -->
    <Feature />
    <!-- 公司新闻 -->
    <News />
    <!-- 立即下载 -->
    <DownloadNow />
    <!-- 关于我们 -->
    <About />
    <!-- 联系我们 -->
    <Contact />
    <!-- 注册信息 -->
    <Notice />
  </div>
</template>

<script>
import Cover from '@/components/Cover/index.vue'
import Feature from '@/components/Feature/index.vue'
import News from '@/components/News/index.vue'
import DownloadNow from '@/components/DownloadNow/index.vue'
import About from '@/components/About/index.vue'
import Contact from '@/components/Contact/index.vue'
import Notice from '@/components/Notice/index.vue'

export default {
  name: 'Home',
  components: {
    Cover,
    Feature,
    News,
    DownloadNow,
    About,
    Contact,
    Notice,
  },
  props: {},

  data() {
    return {
      // 导航栏配置
      navItems: [
        { id: 'cover-anchor-point', name: '下载', url: '#' },
        { id: 'feature-anchor-point', name: '玩法介绍', url: '#' },
        { id: 'news-anchor-point', name: '公司新闻', url: '#' },
        { id: 'about-anchor-point', name: '关于我们', url: '#' },
        { id: 'contact-anchor-point', name: '联系我们', url: '#' },
      ],
      // 当前选中的导航项
      selectedNavItem: {},
      isScrolled: false, // 是够过阈值
      scrollThreshold: 695.2, // 滚动距离阈值
    }
  },

  methods: {
    // 导航栏点击事件
    handleItemClick(item) {
      // 高亮点击部分
      this.selectedNavItem = item
      const _documnet = document.getElementById(item.id)
      if (_documnet) {
        // 滚动到页面指定部分位置
        _documnet.scrollIntoView({ behavior: 'smooth' })
      }
    },
    // 滚动事件监听
    handleScroll() {
      // 改变导航栏的样式
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      this.isScrolled = scrollTop > this.scrollThreshold
      // 随页面滚动到不同区域，导航栏对应被选中
      let _selectedNavItem = 0
      switch (true) {
        case scrollTop <= 695.2:
          _selectedNavItem = 0
          break
        case scrollTop <= 2658.64:
          _selectedNavItem = 1
          break
        case scrollTop < 3716.64:
          _selectedNavItem = 3
          break
        case scrollTop < 4382.64:
          _selectedNavItem = 4
          break
        case scrollTop < 5730.6:
          _selectedNavItem = 5
          break
        default:
          break
      }
      this.selectedNavItem = this.navItems[_selectedNavItem]
    },
  },

  mounted() {
    this.selectedNavItem = this.navItems[0]
    window.addEventListener('scroll', this.handleScroll)
  },

  // 销毁时取消监听，避免造成内存泄漏
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="less" scoped>
// 导航栏
.nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  color: #ffffff80;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  z-index: 100;

  .wrapper {
    height: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-container {
      display: flex;
      align-items: center;
    }

    .logo {
      width: 64px;
      height: 64px;
      top: 8px;
      left: 40px;
    }

    .qh-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 16px; /* 调整这个值 */
    }

    .qh {
      width: 86.75px;
      height: 24px;
      top: 16px;
      //margin-left: 16px; /* 调整这个值 */
      //margin-top: -20px;
    }

    .small-text {
      font-size: 12px; /* 调整字体大小 */
      color: #ffffff; /* 调整字体颜色 */
      margin-top: 5px; /* 调整与图片的间距 */
      margin-left: -15px;
    }

    ul {
      margin: 0;
      padding: 0;
      font-size: 18px;
      list-style: none;
      display: flex;
      align-items: center;

      li {
        margin-left: 40px;
        cursor: pointer;
      }

      li:hover,
      .active {
        color: #fff;
      }
    }
  }
}

.scrolled {
  background: rgba(0, 0, 0, 0.5);
}
</style>
