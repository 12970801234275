<!-- 请注意（举报信息） -->
<template>
  <footer class="footer">
    <section class="notice">
      <img src="https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/logo.png" alt="962110" draggable="false" />
      <p>
        亲爱的市民朋友，西安警方反诈劝阻电话
        <em>96110</em>
        系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听！
      </p>
    </section>
    <ul class="footer-nav">
      <li>玩法介绍</li>
      <li>公司新闻</li>
      <li>关于我们</li>
      <li>联系我们</li>
    </ul>
    <p class="report">
      举报电话：021-60952873 |
      <img src="//imguki.neoclub.cn/miaohong/offical/new-uki/emailGreen.png" alt="" /> <a target="_blank"
        href="http://www.xawljb.cn/">西安互联网举报中心</a>
    </p>
    <p class="copyright">
      © 西安契合派科技有限公司 |
      <a href="https://beian.miit.gov.cn" target="_blank" class="bei"> &nbsp;陕ICP备 2024042426号 </a> <img
        src="//imguki.neoclub.cn/miaohong/outer-h5/policeIcon.png" class="police-icon" />
      <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=沪公网安备31011202014533" target="_blank"
        class="bei"> 陕ICP备 2024042426号 </a> <span style="margin: 0px 6px">|</span> <a href="https://beian.miit.gov.cn/"
        target="_blank" class="bei"> </a>
    </p>
  </footer>
</template>

<script>
export default {
  name: 'Notice',
}
</script>

<style lang="less" scoped>
.footer {
  position: relative;
  padding-top: 100px;
  color: #737e8b;
  font-size: 14px;
  background: #f8f6fa;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  align-items: center;

  .notice {
    display: flex;
    align-items: center;
    margin-bottom: 70px;

    img {
      height: 60px;
      margin-right: 10px;
      display: block;
    }

    p {
      width: 450px;
      line-height: 20px;
    }
  }

  .footer-nav {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    display: flex;

    li {
      padding: 0 8px;
      cursor: pointer;
      border-right: 1px solid #737e8b;
    }
  }

  .report {
    img {
      width: 9.5px;
      height: 10px;
    }

    a {
      color: #39c5fd;
    }
  }

  .copyright {
    display: flex;
    align-items: center;

    .bei {
      text-decoration: none;
      color: #737e8b;
    }

    .police-icon {
      width: 20px;
      height: 20px;
      margin: 0 8px;
    }

    span {
      margin: 0px 6px;
    }
  }
}
</style>
