<!-- 封面 -->
<template>
  <header class="cover" id="cover-anchor-point">
    <section class="wrapper">
      <div class="publicize">
        <img
          src="https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/001-%E5%8F%91%E7%8E%B0%E5%90%8C%E9%A2%91%E7%9A%84%E4%BA%BA.png"
          alt="sign" class="sign" />
        <span class="small-text">有趣、靠谱的全民红娘平台，发现契合的你</span>
      </div>
      <section class="download">
        <div class="btn" v-for="(item, index) in btnList" :key="item.id" @mouseover="handleHover"
          @mouseout="handleHoverOut">
          <div class="btn-text">
            <img :src="item.icon" :alt="item.text" />
            {{ item.text }}
          </div>
          <div class="QRCode">
            <img :src="item.QRCode" alt="QRCode" />
          </div>
        </div>
      </section>
    </section>
  </header>
</template>

<script>
import QRCode_Small from '../../assets/QRCode_Small.jpg'
import smallIcon from '../../assets/smallIcon.png'
import matchmakerIcon from '../../assets/matchmakerIcon.png'

export default {
  name: 'Cover',
  props: {},
  data() {
    return {
      btnList: [
        {
          id: 'SmallProgram',
          icon: smallIcon,
          text: '契合派小程序',
          QRCode: QRCode_Small
        },
        {
          id: 'Matchmaker',
          icon: matchmakerIcon,
          text: '契合派红娘端',
          QRCode: QRCode_Small
        },
      ]
    }
  },
  methods: {
    handleHover(e) {
      // 阻止事件冒泡
      e.stopPropagation()
      // 获取当前元素,防止影响其他元素
      const cutTarget = e.currentTarget
      const targetSub = cutTarget.querySelector('.QRCode')
      targetSub.style.display = 'block'
      // 增加高度
      cutTarget.style.height = '192px'
    },

    handleHoverOut(e) {
      e.stopPropagation()
      const cutTarget = e.currentTarget
      const targetSub = cutTarget.querySelector('.QRCode')
      targetSub.style.display = 'none'
      cutTarget.style.height = '58px'
    }
  }
}
</script>

<style lang="less" scoped>
// 封面
.cover {
  height: 100vh;
  background: url(https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/bj-003.jpg);
  background-size: cover;
  background-position: 50%;
  overflow: hidden;

  .wrapper {
    position: relative;
    top: 32%;
    max-width: 1140px;
    height: 68%;
    width: 100%;
    margin: auto;

    .publicize {
      display: flex;
      align-items: center;
    }

    .sign {
      height: 70px;
      margin-bottom: 90px;
      display: block;
    }

    .small-text {
      width: 449px;
      height: 36px;
      top: 334px;
      left: 47px;
      color: #FFFFFF80;
      margin-left: -490px;
      margin-top: 50px;
    }

    .download {
      display: flex;

      .btn {
        width: 150px;
        height: 58px;
        margin-right: 20px;
        padding: 0 14px;
        color: #3f4658;
        font-size: 18px;
        background: #fff;
        box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        overflow: hidden;
        transition: all 0.2s;
        position: relative;
      }

      .btn-text {
        height: 54px;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          height: 32px;
          margin-right: 6px;
          display: block;
        }
      }

      .QRCode {
        width: 118px;
        height: 118px;
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-top: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
