<!-- 公司新闻 -->
<template>
  <section id="news-anchor-point" class="news">
    <h2 class="title">公司新闻</h2>
    <section class="posts">
      <div class="news-container">
        <ul style="margin-left: 0px" :style="carouselStyle">
          <li v-for="(item, index) in extendedItems" :key="index">
            <img :src="item.imgUrl" alt="news" draggable="false" />
            <section>
              <h3>{{ item.title }}</h3>
              <time>{{ item.time }}</time>
              <p>{{ item.content }}</p>
            </section>
          </li>
        </ul>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: 'News',
  props: {},
  data() {
    return {
      newsItems: [
        {
          imgUrl: 'https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/%E6%A4%8D%E6%A0%91.jpg',
          title: '契合派投身公益事业 去往内蒙古库布齐沙漠进行植树造林活动',
          time: '2024-03-12',
          content: '契合派积极投身公益事业，在内蒙古鄂尔多斯市多次组织公益林植树活动...',
        },
        {
          imgUrl: 'https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/%E5%A5%91%E5%90%88%E6%B4%BE%E4%B8%83%E5%A4%95.jpg',
          title: '契合派七夕活动、发现同频的人',
          time: '2024-08-10',
          content: '火热八月，契合派启动“有你更精彩”项目，与多单位共同举办以“联谊...活动...',
        },
        {
          imgUrl: 'https://ld-image-01.oss-cn-wulanchabu.aliyuncs.com/63857297-580c-422b-816e-fe4bc58b8bdb.jpeg',
          title: '契合派万圣节🎃活动、发现同频的人',
          time: '2024-10-31',
          content: '万圣节，契合派启动“万圣节主题相亲派”项目，此次活动提供各种道具和背景，让参与者拍摄有趣的照片...',
        },
      ],
      currentIndex: 0,
      transitionDuration: 1, // 过渡动画时间（秒）
      autoplayInterval: 2000, // 自动轮播间隔时间（毫秒）
      autoplayTimer: null, // 定时器
    }
  },
  computed: {
    extendedItems() {
      return [...this.newsItems, ...this.newsItems]
    },
    carouselStyle() {
      return {
        transform: `translateX(-${this.currentIndex * 320}px)`,
        transition: `${this.transitionDuration}s ease-in-out`,
      }
    },
  },
  methods: {
    nextSlide() {
      if (this.currentIndex === this.newsItems.length - 1) {
        this.currentIndex++
        this.$nextTick(() => {
          this.carouselStyle.transition = 'none'
          this.currentIndex = 0
          this.$nextTick(() => {
            this.carouselStyle.transition = `${this.transitionDuration}s ease-in-out`
          })
        })
      } else {
        this.currentIndex++
      }
    },
    prevSlide() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.newsItems.length
        this.$nextTick(() => {
          this.carouselStyle.transition = 'none'
          this.currentIndex = this.newsItems.length - 1
          this.$nextTick(() => {
            this.carouselStyle.transition = `${this.transitionDuration}s ease-in-out`
          })
        })
      } else {
        this.currentIndex--
      }
    },
    startAutoplay() {
      this.autoplayTimer = setInterval(() => {
        this.nextSlide()
      }, this.autoplayInterval)
    },
    startAutoplay() {
      this.autoplayTimer = setInterval(() => {
        this.nextSlide()
      }, this.autoplayInterval)
    },
    startAutoplay() {
      this.autoplayTimer = setInterval(() => {
        this.nextSlide()
      }, this.autoplayInterval)
    },
    stopAutoplay() {
      clearInterval(this.autoplayTimer)
    },
  },
  mounted() {
    this.startAutoplay()
  },
  beforeDestroy() {
    this.stopAutoplay()
  },
}
</script>

<style lang="less" scoped>
.news {
  padding: 80px 0 50px;
  background: #f8f6fa;
  .title {
    position: relative;
    margin: 0;
    color: #3f4658;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 1px;
  }
  .title::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -20px;
    width: 80px;
    height: 8px;
    background: #747e8b4d;
    transform: translateX(-50%);
  }
  .posts {
    margin: 0 auto;
    width: 1020px;
    .news-container {
      margin-top: 100px;
      overflow: hidden;
      ul {
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        // overflow: hidden;
        li {
          width: 320px;
          min-width: 320px;
          height: 440px;
          margin: 0 10px;
          background: #fff;
          border: 1px solid #e8e8e8;
          border-radius: 8px;
          overflow: hidden;
          transition: all 0.2s;
          cursor: pointer;
          img {
            width: 320px;
            height: 240px;
            object-fit: cover;
          }
          section {
            padding: 20px 30px;
          }
          h3 {
            margin: 0 0 10px;
            color: #3f4658;
            text-overflow: ellipsis;
            font-size: 24px;
            font-weight: 600;
            line-height: 33px;
            white-space: nowrap;
            display: block;
            overflow: hidden;
          }
          time {
            margin-bottom: 20px;
            color: #747e8b;
            font-size: 16px;
            line-height: 19px;
          }
          p {
            color: #747e8b;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>
